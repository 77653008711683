.Shortcut {
    width: 100%;
}

.LinkLabel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.Label {
    @mixin hyphenateWords;
    flex: 1;
}

.VariantType--solid {
    &.Variant--primary {
        @mixin button var(--support01HKBaseColor), var(--support01HKLighten40Color), var(--blackLighten10Color);
        @mixin shortcutBase var(--blackLighten10Color);
        @mixin shortcutActiveState currentColor, var(--support01HKDarken20Color);
    }

    &.Variant--dark {
        @mixin button var(--blackLighten10Color), var(--blackLighten20Color), var(--whiteBaseColor);
        @mixin shortcutBase var(--whiteBaseColor);
        @mixin shortcutActiveState var(--whiteBaseColor), var(--blackLighten30Color);
    }

    &.Variant--light {
        @mixin button var(--whiteBaseColor), var(--primaryHKBaseColor), var(--blackLighten10Color);
        @mixin shortcutBase var(--whiteBaseColor), var(--primaryHKBaseColor);
        @mixin shortcutActiveState var(--whiteBaseColor), var(--primaryHKDarken20Color);
    }
}

.VariantType--outline {
    &.Variant--primary {
        @mixin shortcutOutline var(--whiteBaseColor), var(--support01HKBaseColor);
        @mixin shortcutActiveState var(--support01HKDarken20Color);
    }

    &.Variant--dark {
        @mixin shortcutOutline var(--blackLighten10Color), var(--primaryHKBaseColor);
        @mixin shortcutActiveState var(--primaryHKDarken20Color);
    }

    &.Variant--light {
        @mixin shortcutOutline var(--whiteBaseColor), var(--primaryHKBaseColor);
        @mixin shortcutActiveState var(--primaryHKDarken20Color);
    }
}

.VariantSize--large {
    &.VariantType--outline {
        @mixin shortcutLargeOutline;
    }

    &.VariantType--solid {
        @mixin shortcutLargeSolid;
    }
}

.VariantSize--small {
    &.VariantType--outline {
        @mixin shortcutSmallOutline;
    }

    &.VariantType--solid {
        @mixin shortcutSmallSolid;
    }
}

.VariantSize--responsiveFromMediumToSmall {
    &.VariantType--outline {
        @mixin shortcutResponsiveFromMediumToSmallOutline;
    }

    &.VariantType--solid {
        @mixin shortcutResponsiveFromMediumToSmallSolid;
    }
}
