.JobOpportunitiesSection {
    @mixin studyPageSectionPadding;
}

.Heading {
    @mixin sectionHeading;
    margin-bottom: 40px;

    @media (--tablet) {
        margin-bottom: 80px;
    }
}

.Variant--withFeaturedArticle {
    & .Content {
        display: flex;
        flex-direction: column;
        gap: var(--gutterWidth);

        @media (--tablet) {
            flex-direction: row;
            align-items: flex-start;

            & .LinkList,
            & .ArticleCard {
                flex: 0.5;
            }
        }
    }
}

.Variant--dark {
    background: var(--blackBaseColor);
    color: var(--whiteBaseColor);
}
