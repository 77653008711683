.StudyPageLinkList {
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.OverlayContentHeading {
    @mixin h30;
    font-weight: 600;
    margin: 0 0 24px;
}

.Teaser {
    @mixin p10;
    @mixin hyphenateWords manual;
    margin-bottom: 32px;
}

.Items {
    @mixin clearList;
    margin-bottom: 64px;
    width: 100%;
}

.Icon {
    @mixin fixedRatioSvgIcon 32px;
}

.Button {
    @mixin buttonScaleAnimationOnChild .Icon, 1.2, 0.95;

    @mixin onFocus {
        @mixin focusVisible;
    }
    margin-left: auto;
    padding: 8px;
}

.Variant--default {
    background: var(--blackLighten60Color);

    & .Icon {
        @mixin svgIconColor var(--blackBaseColor);
    }
}

.Variant--dark {
    background: var(--blackLighten10Color);

    & .Teaser {
        color: var(--whiteBaseColor);
    }

    & .Icon {
        @mixin svgIconColor var(--whiteBaseColor);
    }
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
}
