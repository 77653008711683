@define-mixin shortcutBase $hoverTextColor, $iconColor: currentColor {
    @mixin buttonBase;
    @mixin buttonTransitionBehavior single-arg(background-color, color);

    @mixin onFocus {
        outline-offset: 0;

        & :global(.SvgIcon) {
            color: currentColor;
        }
    }
    padding: 0;
    font-family: var(--serifFontFamily);
    width: 100%;

    &:hover {
        color: $hoverTextColor;

        & :global(.SvgIcon) {
            color: currentColor;
        }
    }

    & :global(.SvgIcon) {
        color: $iconColor;
    }
}

@define-mixin shortcutActiveState $textColor: currentColor, $backgroundColor: initial {
    /* To be removed after we fix buttons active state, see #4070 */
    &:active {
        color: $textColor;
        background: $backgroundColor;
    }
}

@define-mixin shortcutOutline $textColor, $hoverTextColor {
    @mixin shortcutBase $hoverTextColor;
    color: $textColor;
    border-bottom: 1px solid $textColor;
}

@define-mixin shortcutLargeOutline {
    @mixin p20;
    padding: 8px 0;

    @media (--tablet) {
        @mixin h50;
    }
}

@define-mixin shortcutLargeSolid {
    @mixin p20;
    padding: 12px 32px;

    @media (--tablet) {
        @mixin h50;
        padding: 8px 32px;
    }
}

@define-mixin shortcutResponsiveFromMediumToSmallSolid {
    @mixin p30;
    padding: 12px 32px;

    @media (--tablet) {
        @mixin p30;
        padding: 12px 32px;
    }
}

@define-mixin shortcutResponsiveFromMediumToSmallOutline {
    @mixin p30;
    padding: 8px 0;

    @media (--tablet) {
        @mixin p30;
    }
}

@define-mixin shortcutSmallOutline {
    @mixin p40;
    padding: 8px 0;

    @media (--tablet) {
        @mixin p30;
    }
}

@define-mixin shortcutSmallSolid {
    @mixin p40;
    padding: 8px 32px;

    @media (--tablet) {
        @mixin p30;
        padding: 12px 32px;
    }
}
